<div class="contact-content">
    <div class="back-button">
        <mat-icon routerLink="/specialitypricelist">arrow_back</mat-icon>
    </div>
    <div class="title">
        <h3>{{ specialityName | uppercase }} ÁRLISTA MÓDOSÍTÁSA</h3>
    </div>
    <div class="add-new-speciality">
        <div class="search-bar">
            <div class="search-tool">
                <input [(ngModel)]="listFilter" type="search" placeholder="Tétel neve" />
                <div class="icon">
                    <mat-icon aria-hidden="false" aria-label="keyboard_arrow_right icon" fontIcon="search"></mat-icon>
                </div>
            </div>
        </div>
    </div>
    <div class="contact-form">
        <form [formGroup]="priceListForm!" novalidate>
            <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                <div class="array" formArrayName="items" *ngFor="let item of filteredExamination; let i = index" cdkDrag>
                    <div
                        class="form-element"
                        [ngStyle]="
                            i % 2 === 0
                                ? { background: 'white', display: 'flex' }
                                : { background: '#efeff5', display: 'flex', width: '100%' }
                        "
                        [formGroupName]="i"
                    >
                        <div class="field-container">
                            <div class="field">
                                <span> {{ i + 1 }}. Tétel neve</span>
                                <div class="data">
                                    <textarea type="text" formControlName="name" placeholder="Tétel neve"></textarea>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="item.price" class="field-container">
                            <div class="field">
                                <span>Tétel ára ( Ft )</span>
                                <div class="data">
                                    <input type="number" formControlName="price" placeholder="Tétel ára" />
                                </div>
                            </div>
                            <mat-icon (click)="openDeleteExaminationPopup(item.id!)" [ngStyle]="{ cursor: 'pointer' }"
                                >delete_forever</mat-icon
                            >
                        </div>
                        <div *ngIf="item.minPrice" class="field-container">
                            <div class="field">
                                <span>Tétel minimum ára ( Ft )</span>
                                <div class="data">
                                    <input type="number" formControlName="minPrice" placeholder="Tétel ára" />
                                </div>
                            </div>
                        </div>
                        <div *ngIf="item.maxPrice" class="field-container">
                            <div class="field">
                                <span>Tétel maximum ára ( Ft )</span>
                                <div class="data">
                                    <input type="number" formControlName="maxPrice" placeholder="Tétel ára" />
                                </div>
                            </div>
                            <mat-icon (click)="openDeleteExaminationPopup(item.id!)" [ngStyle]="{ cursor: 'pointer' }"
                                >delete_forever</mat-icon
                            >
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div [ngStyle]="{ 'margin-top': '8px' }" class="field-container">
            <div [ngStyle]="{ 'justify-content': 'center', 'margin-top': '8px', 'margin-bottom': '8px' }" class="field">
                <div [ngStyle]="{ 'justify-content': 'center' }" class="data">
                    <button
                        (click)="updatePriceList()"
                        [ngStyle]="{
                            background: '#eee',
                            border: '1px solid #ccc',
                            padding: '8px',
                            'font-weight': 'bold',
                            width: '100%',
                            'border-radius': '4px',
                            cursor: 'pointer'
                        }"
                        [disabled]="!priceListForm?.valid"
                    >
                        {{ specialityName | uppercase }} ÁRLISTA MÓDOSÍTÁSA
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
