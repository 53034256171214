import { Component, Inject, OnInit } from '@angular/core';
import {
    ApiRequestService, ToastmessageService, TranslationService, MAT_ICON,
    TOAST_STATE,
    HttpError
} from '@prekog/pw-common';
import { GetTranslationUseCase } from '../usecases/translation-usecase/read-translation-usecase';
import { Router } from '@angular/router';
import { GetSiteConfigurationResponse } from './getsiteconfigurationresponse';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { SiteConfiguration } from './siteconfiguration';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UpdateSiteConfigRequest } from './updatesiteconfigrequest';
import { UpdateSiteConfigResponse } from './updatesiteconfigresponse';


@Component({
    selector: 'app-basedatas',
    templateUrl: './basedatas.component.html',
    styleUrls: ['./basedatas.component.scss']
})
export class BasedatasComponent implements OnInit {

    getSiteconfigurationUrl = '/websiteconfigurator/get-site-configuration';

    configuratorUrl = '';

    siteConfigurationList:SiteConfiguration[] = [];

    siteConfigForm?:FormGroup;

    updateSiteConfigUrl = '/admin/update-siteconfig';

    get items() {
        return this.siteConfigForm?.get('items') as FormArray;
    }

    constructor(
        public router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorUrl: string,
        private _toastMessageService: ToastmessageService,
        private _fb:FormBuilder
    ) {

        this.configuratorUrl = _configuratorUrl;

        this.createSiteconfigFormArray();
    }

    ngOnInit(): void {
        //this.getSiteConfiguration();
    }

    getSiteConfiguration() {
        this._apiRequestService
            .sendRequest<{}, GetSiteConfigurationResponse>(this.configuratorUrl, true, {}, true, this.getSiteconfigurationUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();
                    });
                })
            )
            .subscribe((response: GetSiteConfigurationResponse) => {
                this.siteConfigurationList  = response.siteConfigurationList as SiteConfiguration[];
                this.siteConfigForm = this._fb.group({
                    items: this._fb.array(this.siteConfigurationList!.map(siteConfig => this._fb.group(siteConfig)))
                });
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    createSiteconfigFormArray() {
        this.siteConfigForm = this._fb.group({
            items: this._fb.array([this.siteConfigForm])
        });
    }

    updateSiteConfig(){
        const updateSiteConfigRequest: UpdateSiteConfigRequest = {
            siteConfigList: this.items.value as SiteConfiguration[]
        }

        this._apiRequestService
        .sendRequest<UpdateSiteConfigRequest, UpdateSiteConfigResponse>(this.configuratorUrl, true, updateSiteConfigRequest, true, this.updateSiteConfigUrl)
        .pipe(
            catchError((error: HttpErrorResponse) => {
                return throwError(() => {
                    const httpError: HttpError = error.error as HttpError;

                    this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                    this.dismissError();

                });
            })
        )
        .subscribe((response: UpdateSiteConfigResponse) => {
            this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
            this.dismissError();
            window.location.reload();

        });

    }

}
