<div class="banners">
    <div class="title">
        <div class="tit">
            <h3>ALAPADATOK</h3>
        </div>
    </div>
    <form [formGroup]="siteConfigForm!" novalidate>
        <div class="array" formArrayName="items" *ngFor="let siteConfig of siteConfigurationList; let i = index" class="banner">
            <div class="pro">
                <div [formGroupName]="i" class="name">
                    <div [ngStyle]="i % 2 === 0 ? { background: 'white' } : { background: '#efeff5' }" class="button">
                        {{ siteConfig.configurationKey }}
                    </div>

                    <input
                        [ngStyle]="{ width: '100%' }"
                        [(ngModel)]="siteConfig.configurationValue"
                        formControlName="configurationValue"
                        id="configurationValue"
                        class="form-control"
                        type="text"
                        required
                    />
                </div>
            </div>
        </div>
    </form>
    <div [ngStyle]="{ 'margin-top': '8px' }" class="field-container">
        <div [ngStyle]="{ 'justify-content': 'center', 'margin-top': '8px', 'margin-bottom': '8px' }" class="field">
            <div [ngStyle]="{ 'justify-content': 'center', width: '70%' }" class="data">
                <button
                    (click)="updateSiteConfig()"
                    [ngStyle]="{ background: '#efeff5', border: '1px solid #ccc', 'font-weight': 'bold', width: '100%' }"
                    [disabled]="!siteConfigForm?.valid"
                >
                    ALAPADATOK MÓDOSÍTÁSA
                </button>
            </div>
        </div>
    </div>
</div>
