import { Component, Inject, OnInit } from '@angular/core';
import { ApiRequestService, ToastmessageService, MAT_ICON, TOAST_STATE, HttpError, Colleague } from '@prekog/pw-common';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { GetColleagueResponse } from './getcolleagueresponse';


@Component({
    selector: 'app-colleagues',
    templateUrl: './colleagues.component.html',
    styleUrls: ['./colleagues.component.scss']
})
export class ColleaguesComponent implements OnInit {

    colleagues: Colleague[] = [];

    colleagueUrl = '/websiteconfigurator/get-colleagues';

    configuratorBaseUrl = '';

    constructor(
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorBaseUrl: string,
        private _apiRequestService: ApiRequestService,
        private _toastMessageService: ToastmessageService

    ) {

        this.configuratorBaseUrl = _configuratorBaseUrl;
    }

    ngOnInit(): void {
        this.getColleagues();
    }

    getColleagues() {
        this._apiRequestService
            .sendRequest<{}, GetColleagueResponse>(this.configuratorBaseUrl, true, {}, true, this.colleagueUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();

                    });
                })
            )
            .subscribe((response: GetColleagueResponse) => {
                this.colleagues = response.colleagueList as Colleague[];
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

}
