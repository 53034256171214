<div class="contact-content">
    <div class="back-button">
        <mat-icon routerLink="/specialities">arrow_back</mat-icon>
    </div>
    <div>
        <h3>SZAKRENDELÉS HOZZÁADÁSA</h3>
        <p>A * - al jelölt mezők kitöltése kötelező!</p>
    </div>
    <div class="contact-form">
        <form [formGroup]="specialityForm!" novalidate>
            <div class="field-container">
                <div class="field">
                    <b>Szakrendelés neve *</b>
                    <div class="data">
                        <input [(ngModel)]="speciality.name" formControlName="name" id="name" class="form-control" type="text" required />
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <b>Szakrendelés munkaköre *</b>
                    <div class="data">
                        <input
                            [(ngModel)]="speciality.jobTitle"
                            formControlName="jobTitle"
                            id="jobTitle"
                            class="form-control"
                            type="text"
                            required
                        />
                    </div>
                </div>
            </div>
        </form>

        <div class="field-container">
            <div class="field">
                <b>Szakrendelés leírása</b>
                <div class="data">
                    <app-tinymcehtmleditor
                        [html]="speciality.description!"
                        [htmlForm]="htmlForm"
                        id="html"
                        class="form-control"
                        type="text"
                        required
                        ngDefaultControl
                    ></app-tinymcehtmleditor>
                </div>
            </div>
        </div>
        <div class="contact-form">
            <h3>Orvos hozzáadása szakrendeléshez (nem kötelező)</h3>
            <mat-icon routerLink="/adddoctor" [ngStyle]="{ 'margin-bottom': '8px', cursor: 'pointer' }">add_circle</mat-icon>
            <div class="field-container">
                <div class="field">
                    <div [ngStyle]="{ 'flex-direction': 'row', width: '100%', 'flex-wrap': 'wrap' }" class="data">
                        <section *ngFor="let doctor of doctors" class="example-section">
                            <mat-checkbox (click)="changeDoctor(doctor)" class="example-margin"
                                ><span [ngStyle]="{ 'font-size': '12px' }">{{ doctor.doctor?.name }}</span>
                            </mat-checkbox>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="contact-form">
        <h3>TÉTEL HOZZÁADÁSA (nem kötelező)</h3>
        <mat-icon (click)="openAddExaminationPopup()" [ngStyle]="{ 'margin-bottom': '8px', cursor: 'pointer' }">add_circle</mat-icon>
        <div *ngFor="let examination of examinationList; let i = index" class="contact-form">
            <div [ngStyle]="{ display: 'flex', width: '100%' }">
                <div class="field-container">
                    <div class="field">
                        <b [ngStyle]="{ width: '100%' }">Tétel neve</b>
                        <div class="data">
                            <span>{{ examination.name }}</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="examination.price !== null" class="field-container">
                    <div class="field">
                        <b [ngStyle]="{ width: '100%' }">Tétel ára:</b>
                        <div class="data">
                            <span>{{ examination.price | currency : 'Ft' : 'symbol' : '1.0-0' }}</span>
                        </div>
                    </div>
                    <mat-icon (click)="removeExamination(i)" [ngStyle]="{ cursor: 'pointer' }">delete_forever</mat-icon>
                </div>
                <div *ngIf="examination.minPrice !== null" class="field-container">
                    <div class="field">
                        <b [ngStyle]="{ width: '100%' }">Tétel minimum ára:</b>
                        <div class="data">
                            <span>{{ examination.minPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="examination.maxPrice !== null" class="field-container">
                    <div class="field">
                        <b [ngStyle]="{ width: '100%' }">Tétel maximum ára:</b>
                        <div class="data">
                            <span>{{ examination.maxPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}</span>
                        </div>
                    </div>
                    <mat-icon (click)="removeExamination(i)" [ngStyle]="{ cursor: 'pointer' }">delete_forever</mat-icon>
                </div>
            </div>
        </div>
    </div>
    <div [ngStyle]="{ 'margin-top': '8px' }" class="field-container">
        <div [ngStyle]="{ 'justify-content': 'center', 'margin-top': '8px', 'margin-bottom': '8px' }" class="field">
            <div [ngStyle]="{ 'justify-content': 'center' }" class="data">
                <button
                    (click)="addSpeciality()"
                    [ngStyle]="{ background: '#eee', border: '1px solid #0E8388', 'font-weight': 'bold', width: '100%' }"
                    [disabled]="!specialityForm?.valid"
                >
                    SZAKRENDELÉS MENTÉSE
                </button>
            </div>
        </div>
    </div>
</div>
