import { Component } from '@angular/core';

@Component({
  selector: 'app-deletespecialitypopup',
  templateUrl: './deletespecialitypopup.component.html',
  styleUrls: ['./deletespecialitypopup.component.scss']
})
export class DeletespecialitypopupComponent {

}
