import { Component, ElementRef, Inject, ViewChild, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { Doctor } from '../doctor';
import { DoctorConsultingHour } from '../updatedoctor/doctorconsultinghour';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { GetSpecialitiesResponse } from '../../specialitypricelist/getspecialitiesresponse';
import { SpecialityWithChecked } from './specialitywithchecked';
import { Speciality } from '../addjobtitle/speciality';
import { UploadFileResponse } from '../updatedoctor/uploadfileresponse';
import { AddconsultinghourpopupTsComponent } from '../addconsultinghourpopup.ts/addconsultinghourpopup.ts.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { AddDoctorRequest } from './adddoctorrequest';
import { AddDoctorResponse } from './adddoctorresponse';
import { environment } from '../../../environments/environment';
import { take, forkJoin, map } from 'rxjs';

@Component({
    selector: 'app-adddoctor',
    templateUrl: './adddoctor.component.html',
    styleUrls: ['./adddoctor.component.scss']
})
export class AdddoctorComponent implements OnInit {


    doctorForm?: FormGroup;

    doctorConsultingHoursList: DoctorConsultingHour[] = [];

    regeneroBaseUrl = '';

    downloadFileUrl = '';

    fileBaseUrl = '';

    uploadFileUrl = '/admin/uploadFile';

    @ViewChild('fileCoverInput') fileCoverInput!: ElementRef;
    fileCover: File | null = null;

    @ViewChild('fileCoverSquareInput') fileCoverSquareInput!: ElementRef;
    fileCoverSquare: File | null = null;

    uploadCoverFile = false;

    uploadCoverSquareFile = false;

    doctor: Doctor = {};

    consultationHourForm?: FormGroup;

    doctorConsultingHour: DoctorConsultingHour = {};

    addConsultingHourUrl = '/admin/add-consulting-hour';

    id = 0;

    getSpecialitiesUrl = '/regenero/get-all-specialities';

    specialities?: Speciality[];

    specialityWithCheckedList?: SpecialityWithChecked[];

    downloadUri = '';

    maxFileSize = 5000000;

    htmlForm?: FormGroup;

    isExtraSmall: Observable<BreakpointState>;

    dialogConfiger: MatDialogConfig = {
        width: '60%',
        backdropClass: 'back-drop-background',
        hasBackdrop: true
    };
    enterAnimationDuration = '500ms';
    exitAnimationDuration = '200ms';

    addDoctorUrl = '/admin/add-doctor';

    baseRegeneroUrl = '';

    downloadSquareUri = '';

    errorMessageCover = 'Kérem válassza ki az orvoshoz tartozó képeket!';

    constructor(
        public _router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('DOWNLOAD_FILE_URL') private _downloadFileUrl: string,
        @Inject('REGENERO_BASE_URL') private _regeneroBaseUrl: string,
        private _toastMessageService: ToastmessageService,
        private _fb: FormBuilder,
        @Inject('FILE_MANAGER_URL') private _fileManagerUrl: string,
        private _translateService: TranslationService,
        private _matDialog: MatDialog,
        private readonly breakpointObserver: BreakpointObserver,
        private ref: ChangeDetectorRef,
        private ngZone: NgZone,
    ) {
        this.downloadFileUrl = _downloadFileUrl;
        this.regeneroBaseUrl = _regeneroBaseUrl;
        this.fileBaseUrl = _fileManagerUrl;
        this.createDoctorForm();
        this.createConsultationHourForm();
        this.createHtmlForm();
        this.isExtraSmall = this.breakpointObserver.observe(Breakpoints.XSmall);
        this.baseRegeneroUrl = environment.apiUrlRegenero;
    }

    ngAfterViewChecked(): void {
        this.ngZone.runOutsideAngular(() => {
            this.ref.detectChanges();
            setTimeout(() => {
                if (sessionStorage.getItem('doctorConsultationList')) {
                    const doctorConsultingHoursList = JSON.parse(sessionStorage.getItem('doctorConsultationList') as string) as DoctorConsultingHour[];
                    if (this.doctorConsultingHoursList.length !== doctorConsultingHoursList.length) {
                        this.doctorConsultingHoursList = [...doctorConsultingHoursList];
                    }
                }

            }, 0);
        });
    }

    ngOnInit(): void {
        if (!sessionStorage.getItem('token')) {
            this._router.navigateByUrl('/');
        }
        else {
            this.getSpecialities();
            if (sessionStorage.getItem('doctorConsultationList')) {
                this.doctorConsultingHoursList = JSON.parse(sessionStorage.getItem('doctorConsultationList') as string) as DoctorConsultingHour[];

            }
            if (sessionStorage.getItem('doctor')) {
                this.doctor = JSON.parse(sessionStorage.getItem('doctor') as string) as Doctor;
            }
        }

    }

    getSpecialities() {
        this._apiRequestService
            .sendRequest<{}, GetSpecialitiesResponse>(this.regeneroBaseUrl, true, {}, true, this.getSpecialitiesUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, 'HttpError hiba!', MAT_ICON.error);
                        this.dismissError();


                    });
                })
            )
            .subscribe((response: GetSpecialitiesResponse) => {
                const specialityWithCheckedList: SpecialityWithChecked[] = [];
                this.specialities = response.specialityList;
                this.specialities?.forEach((speciality) => {
                    if (!speciality.isSpeciality) {
                        const index = this.specialities?.indexOf(speciality);
                        this.specialities?.splice(index!, 1);
                    }
                    else {
                        const specialityWithChecked: SpecialityWithChecked = {
                            speciality: speciality,
                            checked: false
                        }

                        specialityWithCheckedList.push(specialityWithChecked)

                    }
                    this.specialityWithCheckedList = [...specialityWithCheckedList];

                })


            });
    }

    createDoctorForm() {
        this.doctorForm = this._fb.group({
            name: ['', [Validators.required]],
            reservationLink: ['', [Validators.required]],
            routerLink: ['', [Validators.required]]
        });
    }

    createHtmlForm() {
        this.htmlForm = this._fb.group({
            html: ['', [Validators.required]],

        });
    }

    createConsultationHourForm() {
        this.consultationHourForm = this._fb.group({
            day: ['', [Validators.required]],
            startTime: ['', [Validators.required]],
            endTime: ['', [Validators.required]]
        });
    }



    removeConsultingHour(index: number) {
        this.collectDoctorData();
        sessionStorage.setItem('doctor', JSON.stringify(this.doctor));
        window.location.reload()
        if (sessionStorage.getItem('doctorConsultationList')) {
            if (this.doctorConsultingHoursList.length !== 1) {
                this.doctorConsultingHoursList.splice(index, 1);
                sessionStorage.removeItem('doctorConsultationList');
                sessionStorage.setItem('doctorConsultationList', JSON.stringify(this.doctorConsultingHoursList));
            }
            else {
                sessionStorage.removeItem('doctorConsultationList');
            }
        }



    }

    onChange(event: Event) {
        const target = event.target as HTMLInputElement;
        this.fileCover = target.files![0];
        if (this.fileCover.name.split('.').pop() === 'jpg' || 'png' || 'JPEG' || 'jpeg') {
            this.uploadCoverFile = true;
        }
        else {
            this.uploadCoverFile = false;
            this._toastMessageService.displayToastMessage(TOAST_STATE.warning, 'Kérem ".jpg" vagy ".png" kiterjesztésű fájlt töltsön fel!', MAT_ICON.warning);
            this.dismissError();
        }

    }

    onChangeSquare(event: Event) {
        const target = event.target as HTMLInputElement;
        this.fileCoverSquare = target.files![0];
        if (this.fileCoverSquare.name.split('.').pop() === 'jpg' || 'png' || 'JPEG' || 'jpeg') {
            this.uploadCoverSquareFile = true;
        }
        else {
            this.uploadCoverSquareFile = false;
            this._toastMessageService.displayToastMessage(TOAST_STATE.warning, 'Kérem ".jpg" vagy ".png" kiterjesztésű fájlt töltsön fel!', MAT_ICON.warning);
            this.dismissError();
        }
    }

    clearSelectedCoverSquareFile() {
        this.fileCoverSquareInput.nativeElement.value = null;
        this.uploadCoverSquareFile = false;
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    clearSelectedCoverFile() {
        this.fileCoverInput.nativeElement.value = null;
        this.uploadCoverFile = false;
    }

    changeJobTitle(specialityWith: SpecialityWithChecked) {
        this.specialityWithCheckedList?.forEach((specialityWithCheck => {
            if (specialityWith.speciality?.jobTitle === specialityWithCheck.speciality?.jobTitle) {
                specialityWithCheck.checked = !specialityWithCheck.checked
            }
        }))
    }

    uploadDoctorImage() {
        if (!this.uploadCoverFile || !this.uploadCoverSquareFile) {
            this._toastMessageService.displayToastMessage(TOAST_STATE.warning, this.errorMessageCover, MAT_ICON.warning);
            this.dismissError();
            return;
        }

        if (
            this.fileCover?.size! > this.maxFileSize ||
            this.fileCoverSquare?.size! > this.maxFileSize
        ) {
            this._toastMessageService.displayToastMessage(
                TOAST_STATE.error,
                this._translateService.translate('A maximum fájl korlát 5MB. Kérem ennél kisebb méretű fájlt töltsön fel!'),
                MAT_ICON.error
            );
            this.dismissError();
            return;
        } else {
            const fileCoverObservable = this._apiRequestService
                .uploadFile(this.fileCover!, true, true, this.fileBaseUrl, this.uploadFileUrl)
                .pipe(take(1));

                const fileSquareObservable = this._apiRequestService
                    .uploadFile(this.fileCoverSquare!, true, true, this.fileBaseUrl, this.uploadFileUrl)
                    .pipe(take(1));
                return forkJoin([fileCoverObservable, fileSquareObservable])
                    .pipe(
                        map(([fileCover, fileSquare]) => {
                            const fileCoverResponse = fileCover as UploadFileResponse;
                            const fileSquareResponse = fileSquare as UploadFileResponse;
                            this.downloadUri = fileCoverResponse.downloadUri as string;
                            this.downloadSquareUri = fileSquareResponse.downloadUri as string;
                            this.addDoctorPart();
                        })
                    )
                .subscribe(() => { });


        }
    }

    addDoctorPart() {
        this.collectDoctorData();

        const addDoctorRequest: AddDoctorRequest = {
            doctor: this.doctor,
            doctorConsultingHourList: this.doctorConsultingHoursList,
            jobTitleList: this.collectJobTitles()
        }
        this._apiRequestService
            .sendRequest<AddDoctorRequest, AddDoctorResponse>(this.regeneroBaseUrl, true, addDoctorRequest, true, this.addDoctorUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;
                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                            MAT_ICON.error
                        );
                        this.dismissError();
                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response: AddDoctorResponse) => {
                this._toastMessageService.displayToastMessage(
                    TOAST_STATE.success,
                    response.responseMessage!,
                    MAT_ICON.success
                );
                this.dismissError();
                sessionStorage.removeItem('doctor');




            });
    }

    openDeleteAddConsultingHourPopup() {
        const popupDialog = this._matDialog.open(AddconsultinghourpopupTsComponent, {
            width: 'calc(100% - 50px)',
            maxWidth: '100vw',
            height: '80%'
        });
        const xsmallDialogSubscription = this.isExtraSmall.subscribe((size: any) => {
            if (size.matches) {
                popupDialog.updateSize('100vw', '100%');
            } else {
                popupDialog.updateSize('calc(60% - 50px)', '');
            }
        });

        popupDialog.afterClosed().subscribe(() => {
            xsmallDialogSubscription.unsubscribe();
        });
    }

    collectDoctorData() {
        this.doctor = {
            name: this.doctorForm?.controls['name'].value as string | undefined,
            introduction: this.htmlForm?.controls['html'].value as string | undefined,
            reservationLink: this.doctorForm?.controls['reservationLink'].value as string | undefined,
            image: this.downloadUri,
            routerLink: this.doctorForm?.controls['routerLink'].value as string | undefined

        };
        if (this.downloadSquareUri) {
            this.doctor.thumbnailUrl = this.downloadSquareUri;
        }

    }

    collectJobTitles(): Array<string> {
        let jobTitleList: Array<string> = [];

        this.specialityWithCheckedList?.forEach((specialityWithChecked) => {
            if (specialityWithChecked.checked) {
                jobTitleList.push(specialityWithChecked.speciality?.jobTitle!);
            }
        })

        return jobTitleList;
    }


    addDoctor() {
        if (
            this.fileCover?.size! > this.maxFileSize
        ) {
            this._toastMessageService.displayToastMessage(
                TOAST_STATE.error,
                this._translateService.translate('A maximum fájl korlát 5MB. Kérem ennél kisebb méretű fájlt töltsön fel!'),
                MAT_ICON.error
            );
            this.dismissError();
            return;
        }
        else {
            this.uploadDoctorImage();

        }

    }

}
